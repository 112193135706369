<template>
  <div id="School">
    <h2>部分合作学校</h2>
    <!-- <p>Part Of Partner School</p> -->
    <div class="school-box">
      <ul>
        <li v-for="(item, index) in SchoolList" :key="index">
          <img :src="item.img" alt="" />
          <p>
            {{ item.name }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import logo_item_01 from '@/assets/images/partnersCustomers/logo_item_01.png'
import logo_item_02 from '@/assets/images/partnersCustomers/logo_item_02.png'
import logo_item_03 from '@/assets/images/partnersCustomers/logo_item_03.png'
import logo_item_04 from '@/assets/images/partnersCustomers/logo_item_04.png'
import logo_item_05 from '@/assets/images/partnersCustomers/logo_item_05.png'
import logo_item_06 from '@/assets/images/partnersCustomers/logo_item_06.png'
import logo_item_07 from '@/assets/images/partnersCustomers/logo_item_07.png'
import logo_item_08 from '@/assets/images/partnersCustomers/logo_item_08.png'
import logo_item_09 from '@/assets/images/partnersCustomers/logo_item_09.png'
import logo_item_10 from '@/assets/images/partnersCustomers/logo_item_10.png'
import logo_item_11 from '@/assets/images/partnersCustomers/logo_item_11.png'
import logo_item_12 from '@/assets/images/partnersCustomers/logo_item_12.png'
import logo_item_13 from '@/assets/images/partnersCustomers/logo_item_13.png'
import logo_item_14 from '@/assets/images/partnersCustomers/logo_item_14.png'
import logo_item_15 from '@/assets/images/partnersCustomers/logo_item_15.png'
import logo_item_16 from '@/assets/images/partnersCustomers/logo_item_16.png'
import logo_item_17 from '@/assets/images/partnersCustomers/logo_item_17.png'
import logo_item_18 from '@/assets/images/partnersCustomers/logo_item_18.png'
import logo_item_19 from '@/assets/images/partnersCustomers/logo_item_19.png'
import logo_item_20 from '@/assets/images/partnersCustomers/logo_item_20.png'
import logo_item_21 from '@/assets/images/partnersCustomers/logo_item_21.png'

export default {
  name: 'School',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {
      SchoolList: [
        {
          name: '成都市第七中学',
          img: logo_item_15,
        },
        {
          name: '上海市浦东复旦附中分校',
          img: logo_item_04,
        },
        {
          name: '天津第二南开学校',
          img: logo_item_05,
        },
        {
          name: '哈尔滨市第三中学校',
          img: logo_item_09,
        },
        {
          name: '辽宁省实验中学',
          img: logo_item_20,
        },
        {
          name: '福建师范大学附属中学',
          img: logo_item_07,
        },
        {
          name: '厦门大学附属科技中学',
          img: logo_item_11,
        },
        {
          name: '北京市第三十五中学',
          img: logo_item_21,
        },
        {
          name: '北京市陈经纶中学',
          img: logo_item_03,
        },
        {
          name: '北京市潞河中学',
          img: logo_item_13,
        },
        {
          name: '浙江大学附属中学',
          img: logo_item_08,
        },
        {
          name: '南京师范大学附属中学',
          img: logo_item_14,
        },
        {
          name: '杭州学军中学',
          img: logo_item_12,
        },
        {
          name: '江西师范大学附属中学',
          img: logo_item_01,
        },
        {
          name: '深圳外国语学校',
          img: logo_item_17,
        },
        {
          name: '青岛市实验高级中学',
          img: logo_item_06,
        },
        {
          name: '四川省天府七中',
          img: logo_item_19,
        },
        {
          name: '华东师范大学澄迈实验中学',
          img: logo_item_02,
        },
        {
          name: '威海市第四中学',
          img: logo_item_10,
        },
        {
          name: '东莞市松山湖北区学校',
          img: logo_item_18,
        },

        {
          name: '深圳市南山区香山里小学',
          img: logo_item_16,
        },
      ],
    }
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%School {
  width: 100%;
  padding-top: 155px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .school-box {
    margin: 78px auto 0;
    width: 80%;
    max-width: 1320px;
    overflow: hidden;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
    ul {
      border-radius: 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(198px, 1fr));
      grid-auto-flow: row dense;
      margin-right: -2px;
      margin-bottom: -2px;
      li {
        background: $--color-white;
        align-items: center;
        padding: 35px 0 22px 0;
        border-right: 2px #e8e8e8 solid;
        border-bottom: 2px #e8e8e8 solid;
        img {
          height: 98px;
          width: 98px;
        }
      }
    }
    // ul li:nth-child(7n) {
    //   border-right: none;
    // }
  }
}

.view-pc > #School {
  @extend %School;
}

.view-mobile > #School {
  @extend %School;
  margin-top: 44px;
  padding-top: 15px;
  h2 {
    font-size: 22px;
  }
  .school-box {
    margin-top: 30px;
  }
  > p {
    font-size: 14px;
    &::after {
      left: 42% !important;
      margin-top: 35px;
    }
  }

  @media screen and (max-width: 768px) {
  }
}
</style>
